.gastro-card-dynamic {
  &__deferred-payment-banner {
    border: 2px solid #fbd23a;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    padding: 15px;
    min-height: 86px;
    background-image: url('@assets/images/paypo-bg.webp');
    background-size: cover;
    background-position: 0 -15px;

    @include screen-md-max {
      text-align: center;
      flex-direction: column;
      background-position: center;
    }
  }

  &__amount-box {
    &__offer {
      border-top: 1px solid rgba(177, 183, 189, 0.2);
      margin-top: auto;
      background-color: #ecf2f7;
      margin-inline: -20px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        width: 50%;
        height: 100%;
        background: linear-gradient(66deg, #ffffff00, #edf3f7 105%);

        pointer-events: none;
      }

      &__description-btn {
        background-color: #ecf2f7;
        margin-inline: -20px;
      }

      &__prices {
        padding-left: 25px;
        position: relative;
        min-width: 140px;
        max-width: 140px;
        width: 140px;

        &:after {
          content: '';
          position: absolute;
          left: 20px;
          top: -15px;
          z-index: 2;
          transform: translateY(-35%);
          width: 1px;
          height: 20px;
          background-color: #979797;
        }
      }

      &__progress {
        margin-top: 20px;
        margin-bottom: 10px;
        width: 100%;
        height: 3px;
        border-radius: 50%;
        background: linear-gradient(
          270deg,
          rgba(60, 213, 46, 0.25) 0%,
          rgba(60, 213, 46, 0.6) 50%,
          rgba(60, 213, 46, 0.25) 100%
        );

        &__value-indicator {
          width: 0;
          height: 0;
          top: 5px;
          left: 25px;
          position: absolute;
          border-style: solid;
          border-width: 11px 5.5px 0 5.5px;
          border-color: $primary transparent transparent transparent;
          z-index: 1;
        }
      }
    }
  }

  &__offer-descripion-modal {
    &__image {
      height: calc(100% + 50px);
      object-fit: cover;
      position: absolute;
      right: 0;
      top: -50px;
      z-index: 0;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 4px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:103";
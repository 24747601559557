@mixin badge {
  padding: 1px 5px;
  border-radius: 4px;
  font-weight: 700;
  width: fit-content;
}

.badge-success {
  @include badge();
  background-color: rgba(44, 143, 65, 0.1);
  border: 1px solid rgba(44, 143, 65, 0.4);
  color: $success;
}

.badge-danger {
  @include badge();

  background-color: rgba(44, 143, 65, 0.1);
  border: 1px solid rgba(44, 143, 65, 0.4);
  color: $danger;
}

.badge-warning {
  @include badge();

  background-color: #b592171a;
  border: 1px solid #c9812a66;
  color: $primary;
}

.badge-pulsing {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(114, 114, 114, 0.7);
  }

  50% {
    transform: scale(1.07);
    box-shadow: 0 0 0 5px rgba(114, 114, 114, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(114, 114, 114, 0);
  }
}

;@import "sass-embedded-legacy-load-done:133";
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: $light-azure !important;
  font-size: 13px;
  color: $darker-gray;
  scroll-padding-top: 200px;

  * {
    font-family: museo_sans, sans-serif !important;
  }

  @include screen-xl-min {
    font-size: 14.5px;
  }

  @include screen-xs-min {
    font-size: 12.5px;
  }

  &.block-scroll {
    overflow: hidden;
    touch-action: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }

  @include screen-lg-max {
    background-color: white !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.bg-dark-blue {
  background: $dark-blue;
}

.bg-light-azure {
  background: $light-azure !important;
}

.bg-light-gray {
  background: $light-gray !important;
}

.bg-lighter-gray {
  background: $lighter-gray;
}

.bg-gold-gradient {
  background: $gold-gradient;
}

.bg-darker-gray {
  background: $darker-gray;
}

.text-darker-gray {
  color: $darker-gray;
}

.font-3xs {
  font-size: $font-3xs;
}

.font-xxs {
  font-size: $font-xxs;
}

.font-xs {
  font-size: $font-xs !important;
}

.font-sm {
  font-size: $font-sm;
}

.font-md {
  font-size: $font-md;
}

.font-lg {
  font-size: $font-lg;
}

.font-xl {
  font-size: $font-xl;
}

.font-xxl {
  font-size: $font-xxl;
}

.font-xxxl {
  font-size: $font-xxxl;
  line-height: 1;
}

.font-header {
  font-size: $font-header;
}

.font-header-lg {
  font-size: $font-header-lg;
}

.lh-initial {
  line-height: initial;
}

.fw-semi-bold {
  font-weight: 500;
}

.btn-transparent {
  background-color: transparent !important;
  transition: opacity 150ms ease-in-out !important;
  opacity: 1;

  &:hover {
    opacity: 0.8;
  }
}

.border-darker-gray {
  border: 1px solid $darker-gray !important;
  border-radius: 4px;
}

.btn {
  min-height: 40px;
}

.btn-tall {
  height: 42px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.h-fit {
  height: fit-content;
}

.z-index-1 {
  z-index: 1;
}

.btn-unstyled {
  border: none !important;
  background: transparent !important;
  padding-inline: 0;
}

.btn-mobile-w-100 {
  @include screen-xs-max {
    width: 100%;
    margin: 0 auto;
  }
}

.modal-semi-lg {
  max-width: 660px !important;
}

.opacity-animated {
  transition: opacity 0.5s ease-in-out;
}

.price-crossed {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    background-color: $danger;
    transform: rotate(-20deg);
  }
}

.underline-shadow {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: calc(100% + 4px);
    height: 4px;
    left: -2px;
    bottom: 1px;
  }

  &.is-white {
    &:before {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}

.button {
  transition: opacity 0.2s linear;
}

button:active {
  opacity: 0.6;
}

.is-card-selected {
  border-color: $success !important;
  box-shadow: 0 0 3px 0 $success;
}

.is-another-card-selected {
  opacity: 70%;
}

.custom-alert {
  border: 1px solid rgba(124, 139, 152, 0.5) !important;
  background-color: rgba(236, 245, 252, 0.5) !important;
  padding-block: 15px;
}

;@import "sass-embedded-legacy-load-done:5";
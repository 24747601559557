.custom-modal {
  .modal-title {
    width: 100%;
  }

  &.is-login-modal {
    .modal-content {
      max-width: 900px;
      margin: 0 auto;
      border-top-right-radius: 1rem;
    }

    .custom-modal-with-image__image {
      width: 45%;
      border-top-right-radius: calc(0.5rem + 6px);
    }

    .custom-modal-with-image__content {
      width: 55%;
    }

    .custom-modal__close-button {
      z-index: 2;

      &:after {
        border-top-right-radius: calc(0.5rem + 2px);
      }
    }

    @include screen-sm-max {
      .custom-modal-with-image__image {
        display: none;
      }

      .custom-modal-with-image__content {
        width: 100%;
        max-width: 100%;
      }

      .custom-modal__close-button {
        &:after {
          background-color: white;
        }

        &:before {
          color: $darker-gray;
        }
      }
    }
  }
}

.custom-modal-with-image {
  .modal-header {
    padding-top: 0 !important;
  }

  .modal-body {
    position: initial;
  }

  .modal-dialog {
    border-top-right-radius: 23px;
    margin: 0 auto;

    @include screen-xl-min {
      border-top-right-radius: 3px;
    }
  }

  &__content,
  .custom-modal__title {
    max-width: 70%;
  }

  .custom-modal__close-button {
    @include screen-sm-max {
      opacity: 0;
    }

    &:before {
      font-size: 20px;
      z-index: 3;
      color: $white;
      position: absolute;
      right: 16px;
      top: 18px;
    }

    &:after {
      z-index: 2;
      content: '';
      background-color: #3f3f3f;
      height: 55px;
      width: 55px;
      position: absolute;
      right: 0;
      top: 0;
      border-top-right-radius: 19px;

      @include screen-xl-min {
        border-top-right-radius: 3px;
      }
    }
  }

  &__image {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
    width: 30%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 19px;
    border-bottom-right-radius: 3px;

    @include screen-xl-min {
      border-top-right-radius: 3px;
    }
  }
}

.is-dark-close {
  .modal-header {
    position: relative;

    .modal-close-button {
      border: 0;
      box-shadow: none;
      background-color: #3f3f3f;
      border-top-right-radius: 2px;
      content: '';
      height: 100%;
      right: 0;
      top: 0;
      position: absolute;
      width: 50px;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

;@import "sass-embedded-legacy-load-done:126";
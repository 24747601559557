.header {
  box-shadow: 0 0 2px 0 rgba(66, 68, 90, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 8px;
  position: sticky;
  top: 0;
  z-index: 10;
}

;@import "sass-embedded-legacy-load-done:127";
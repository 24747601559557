.progress-bar {
  &__wrapper {
    .progress {
      background: rgba(144, 153, 161, 0.40);
      height: 10px;
    }

    .progress-bar {
      background: $secondary;
      border-radius: 0.375rem;
      height: 10px;
    }

  }
}

;@import "sass-embedded-legacy-load-done:111";
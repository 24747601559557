$thumb-background: #cdcfd2;

.slider {
  position: relative;

  &__track {
    position: absolute;
    border-radius: 3px;
    height: 5px;
    background-color: $thumb-background;
    width: 100%;
    z-index: 1;
  }

  &__range {
    position: absolute;
    border-radius: 3px;
    height: 5px;
    background-color: $gold;
    z-index: 2;
  }

  &__left-value {
    left: 6px;
  }

  &__right-value {
    right: -4px;
  }

  &__left-value,
  &__right-value {
    position: absolute;
    color: $darker-gray;
    font-size: 12px;
    font-weight: 500;
    margin-top: 20px;
  }

  &__wrapper {
    margin-top: 8px;

    /* Removing the default appearance */
    .thumb,
    .thumb::-webkit-slider-thumb {
      -webkit-appearance: none;
      -webkit-tap-highlight-color: transparent;
    }

    .thumb {
      pointer-events: none;
      position: absolute;
      height: 0;
      width: 100%;
      outline: none;
    }

    .thumb--left {
      z-index: 3;
    }

    .thumb--right {
      z-index: 4;
    }

    /* For Chrome browsers */
    .thumb::-webkit-slider-thumb {
      background-color: white;
      border: 2px solid $gold;
      border-radius: 50%;
      box-shadow: 0 0 1px 1px $thumb-background;
      cursor: pointer;
      height: 15px;
      width: 15px;
      margin-top: 4px;
      pointer-events: all;
      position: relative;
    }

    /* For Firefox browsers */
    .thumb::-moz-range-thumb {
      background-color: white;
      border: 2px solid $gold;
      border-radius: 50%;
      box-shadow: 0 0 1px 1px $thumb-background;
      cursor: pointer;
      height: 15px;
      width: 15px;
      margin-top: 4px;
      pointer-events: all;
      position: relative;
    }
  }
}

;@import "sass-embedded-legacy-load-done:135";
.gastro-card-boost {
  &__price {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 15px 20px;

    &__content {
      z-index: 1;
      position: relative;
    }

    &__hr {
      text-align: center;
      color: $primary;
      font-size: $font-sm;
      position: relative;

      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50px;
        height: 1px;
        background-color: $primary;
      }

      &:after {
        left: 50px;
        z-index: 1;
      }

      &:before {
        right: 50px;
        z-index: 1;
      }
    }

    &__amount {
      background-color: $primary;
      padding: 8px 15px;
      color: white;
      margin-right: 10px;
      font-weight: 700;
      display: flex;
      align-items: center;
      line-height: 1;
      border-radius: 4px;
    }

    &:before {
      content: '';
      position: absolute;
      top: -10px;
      height: calc(100% + 20px);
      background: #0f2453;
      opacity: 90%;
      z-index: 1;
      border-top-right-radius: 8px;
      clip-path: polygon(52% 0, 80% 100%, 0 100%, 0 0);
      left: 0;
      width: 430px;
    }
  }

  &__personal-data {
    &__information-box {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

      &:after {
        width: calc(100% - 180px);
      }

      @include screen-xs-max {
        grid-template-columns: 1fr;

        &:after {
          content: none;
        }
      }
    }
  }

  &__amount-box {
    display: inline-block;
    border: 1px solid rgba(144, 153, 161, 0.7);
    border-radius: 4px;
    background-color: rgba(239, 243, 247, 0.7);
    padding: 20px;
    width: 30%;

    .spin-input__container {
      //  margin-top: 58px;
      max-width: 100%;
    }

    @include screen-sm-max {
      width: 100%;
      padding-bottom: 40px;
    }
  }

  &__amount-percentage-box {
    position: relative;
    border: 1px solid rgba(178, 132, 62, 0.6);
    background-color: rgba(185, 145, 83, 0.1);
    border-radius: 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20%;
    min-width: 220px;

    @include screen-md-max {
      width: 100%;
      padding-bottom: 40px;
    }
  }

  &__amount-selection-box {
    position: relative;
    border: 1px solid rgba(178, 132, 62, 0.6);
    border-radius: 4px;
    padding: 20px 0 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 50%;
    background-image: url('@assets/images/offers/gastro-card-boost/gastro-card-boost-price-box.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include screen-sm-max {
      width: 100%;
      flex-direction: column;
      align-items: center;
      padding-left: 0;
      padding-top: 40px;

      .trapezoid__wrapper {
        align-self: end;
      }
    }

    @include screen-sm-max {
      background-image: url('@assets/images/offers/gastro-card-boost/gastro-card-boost-price-box-mobile.webp');
    }

    &.is-gastro_card_black_week_2024,
    &.is-gastro_card_christmas_2024,
    &.is-gastro-card-dynamic,
    &.is-gastro_card_2026_november {
      background-size: cover;
      align-items: flex-start;

      @include screen-sm-max {
        align-items: center;
      }

      .trapezoid__wrapper {
        position: absolute;
        bottom: 0;
        right: -1px;
      }
    }

    &.is-gastro_card_2026_november {
      background-image: url('@assets/images/offers/gastro-card/gastro-card-product.webp');

      @include screen-sm-max {
        background-image: url('@assets/images/offers/gastro-card/gastro-card-product-mobile.webp');
      }
    }

    &.is-gastro-card-dynamic {
      background-image: url('@assets/images/offers/gastro-card-dynamic/gastro-card-dynamic.webp');
    }

    &.is-gastro_card_black_week_2024 {
      background-image: url('@assets/images/offers/gastro-card/gastro-card-black-week-background.webp');
    }

    &.is-gastro_card_christmas_2024 {
      background-image: url('@assets/images/offers/gastro-card/gastro-card-black-week-background.webp');
    }

    &.is-gastro_card_promo_february_2025 {
      background-image: url('@assets/images/offers/gastro-card/gastro-card-black-week-background.webp');
    }

    &.is-selected {
      border-color: $success;
      box-shadow: 0 0 3px 0 $success;
    }
  }

  &__amount {
    &__plus,
    &__equal {
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        background-color: $primary;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
      }
    }

    &__plus {
      position: absolute;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
      color: white;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        width: 40px;
        background-color: #f8f4ed;
        border-top-left-radius: 110px;
        border-top-right-radius: 110px;
        border: 1px solid rgba(178, 132, 62, 0.6);
        border-bottom: 0;
        height: 25px;
        left: -20px;
      }

      @include screen-sm-max {
        top: -15px;
        left: 50%;
        transform: translateX(-50%);

        &:after {
          transform: translateX(-50%);
          left: 50%;
          height: 24px;
          top: -9px;
        }
      }

      .uil-plus {
        position: relative;
        z-index: 2;
      }
    }

    &__equal {
      position: absolute;
      right: -9px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 20px;
      color: white;
      z-index: 2;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        width: 40px;
        background-color: #f1e0b8;
        border-top-left-radius: 110px;
        border-top-right-radius: 110px;
        border: 1px solid rgba(178, 132, 62, 0.6);
        border-bottom: 0;
        height: 22px;
        left: -23px;
      }

      &.is-gastro_card_black_week_2024,
      &.is-gastro_card_promo_february_2025,
      &.is-gastro_card_christmas_2024 {
        &:after {
          background-color: black;
        }
      }

      &.is-gastro-card-dynamic {
        &:after {
          background-color: black;
        }
      }

      &.is-selected {
        &:after {
          border-color: $success;
          box-shadow: 0px -1px 2px 0px $success;
        }
      }

      @include screen-sm-max {
        top: auto;
        bottom: -18px;
        right: 48%;
        transform: translateX(-50%);

        &:after {
          transform: translateX(-50%);
          left: 50%;
          height: 24px;
          top: -6px;
        }
      }

      &__sign {
        position: relative;
        z-index: 2;
      }
    }
  }
}

.gastro-card {
  &__personal-data {
    &__information-box {
      grid-template-columns: 1fr 1fr 1fr 1fr;

      &:after {
        width: calc(100% - 250px);
      }

      @include screen-xs-max {
        grid-template-columns: 1fr;

        &:after {
          content: none;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:102";
.expandable-product-description {
  max-height: 70px;
  transition: max-height 200ms linear;
  overflow: hidden;
  position: relative;

  &:before {
    pointer-events: none;
    content: '';
    position: absolute;
    left: 0;
    top: 30px;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, #ffffff70 100%);
    transition: opacity 200ms linear;
    opacity: 1;
  }

  &__toggle__icon {
    transition: transform 200ms linear;
  }

  &__button {
    padding: 0;
    background-color: transparent !important;
    border: none;
  }

  &__wrapper {
    &.is-full-height {
      .expandable-product-description {
        height: auto;
        max-height: initial;

        &:before {
          opacity: 0;
        }
      }
    }

    &.is-collapsed {
      .expandable-product-description {
        max-height: 1000px;

        &:before {
          opacity: 0;
        }
      }

      .expandable-product-description__toggle__icon {
        transform: rotate(180deg);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:123";
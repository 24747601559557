$optimum-variant: #af81c6;
$comfort-variant: #e95442;
$lux-variant: #fbb302;

.subscription-with-benefit {
  margin-top: 60px;
  position: relative;

  @include screen-sm-max {
    margin-top: -20px;
  }

  &:after {
    content: '';
    position: absolute;
    left: -10px;
    top: -10px;
    height: calc(100% + 20px);
    background: #0f2453;
    opacity: 90%;
    width: 340px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    @include screen-sm-max {
      border-radius: 0;
      width: 100%;
      left: 0;
      height: 100%;
      top: 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    height: calc(100% + 20px);
    background: #0f2453;
    opacity: 90%;
    z-index: 2;
    border-top-right-radius: 8px;
    clip-path: polygon(0 0, 100% 0, 0% 100%, 0 100%);
    left: 330px;
    width: 240px;

    @include screen-sm-max {
      display: none;
    }
  }

  &__price-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 300px;
    height: 300px;
    overflow: hidden;

    .offer-slide__price__container {
      bottom: 30px;
      top: auto;
      right: 20px;

      &:before {
        bottom: -110px;
        height: 313px;
        right: -210px;
        transform: rotate(47deg);
        width: 337px;
        z-index: 2;
      }

      &.animate {
        animation: priceBoxAnimation 0.5s infinite;

        .offer-slide__price__amount {
          animation: priceAnimation 0.5s infinite;
        }
      }
    }

    &__mobile {
      padding: 20px;
      border-radius: 4px;
      border: 1px solid rgba(185, 145, 83, 0.478);
      font-size: 14px;
      max-width: 570px;
      width: 100%;

      @include screen-md-min {
        display: none;
      }
    }
  }

  &__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    z-index: 0;
    pointer-events: none;
  }

  &__content {
    padding: 30px;
    position: relative;
    z-index: 2;
    color: white;

    @include screen-sm-max {
      padding-top: 50px;
    }
  }

  &__variant-card {
    border-radius: 4px;
    margin: 0 auto;

    &.is-selected {
      opacity: 1;
      border-color: $success !important;
      box-shadow: 0 0 3px 0 $success;
    }

    &.is-another-selected {
      opacity: 70%;
    }

    .trapezoid__wrapper {
      margin-right: -1px;
      margin-bottom: -1px;
      border-bottom-right-radius: 4px;
      align-self: end;
    }

    &.is-optimum {
      border: 1px solid $optimum-variant;
      background-color: rgba(175, 129, 198, 0.1);

      .circle-check {
        background-color: $optimum-variant;
      }

      .subscription-with-benefit__variant-card__header {
        color: $optimum-variant;
      }

      .subscription-with-benefit__variant-card__footer {
        border-top: 1px solid $optimum-variant;
        background-color: rgba(175, 129, 198, 0.2);
      }
    }

    &.is-comfort {
      border: 1px solid $comfort-variant;
      background-color: rgba(233, 84, 66, 0.1);

      .circle-check {
        background-color: $comfort-variant;
      }

      .subscription-with-benefit__variant-card__header {
        color: $comfort-variant;
      }

      .subscription-with-benefit__variant-card__footer {
        border-top: 1px solid $comfort-variant;
        background-color: rgba(233, 84, 66, 0.2);
      }
    }

    &.is-lux {
      border: 1px solid $lux-variant;
      background-color: rgba(251, 179, 2, 0.1);

      .circle-check {
        background-color: $lux-variant;
      }

      .subscription-with-benefit__variant-card__header {
        color: $lux-variant;
      }

      .subscription-with-benefit__variant-card__footer {
        border-top: 1px solid $lux-variant;
        background-color: rgba(251, 179, 2, 0.2);
      }
    }

    &__image {
      object-fit: cover;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &__header {
      font-weight: bold;
      font-size: $font-xxxl;
      text-align: center;
    }
  }

  &__payment-bar {
    background: #4e565e;
    border-radius: 4px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include screen-sm-max {
      flex-direction: column;
      text-align: center;
      justify-content: start;
      width: auto;
      margin-inline: 1rem;

      button {
        margin-top: 10px;
        width: 100%;
      }
    }

    &__price {
      font-size: 25px;
    }
  }

  &__personal-data {
    &__information-box {
      border: 1px solid $gold;
      border-radius: 4px;
      padding: 30px 20px;
      align-items: start;
      display: grid;
      column-gap: 15px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 42px;
        width: calc(100% - 190px);
        height: 1px;
        border-top: 1px dashed $primary;
        transform: translateX(-50%);
        left: 50%;
        z-index: 0;
      }

      &__point {
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        min-height: 25px;
        width: 25px;
        min-width: 25px;
        border-radius: 50%;
        background-color: $gold;
        color: white;
        font-weight: bold;
        position: relative;
        font-size: 15px;
        margin-right: 15px;

        &:after {
          content: '';
          position: absolute;
          width: calc(100% + 10px);
          height: calc(100% + 10px);
          left: -5px;
          top: -5px;
          background-color: $gold;
          opacity: 10%;
          border-radius: 50%;
        }
      }

      @include screen-xs-max {
        grid-template-columns: 1fr;

        &:after {
          content: none;
        }
      }
    }

    &__login {
      &__icon {
        font-size: 25px;
      }
    }
  }

  &__gallery-img {
    height: 470px;

    @include screen-md-max {
      padding: 0;
      height: 300px;
    }
  }

  &__description-modal {
    &__image {
      height: 340px;
      object-fit: cover;
      position: absolute;
      right: 0;
      top: -49px;
      z-index: 0;

      @include screen-xs-max {
        position: relative;
        top: 0;
        height: auto;
        width: calc(100% + 25px);
      }
    }

    &.modal-dialog {
      max-width: 700px;
      width: 700px;

      @include screen-xs-max {
        width: auto;

        max-width: 100%;
      }
    }
  }
}

@keyframes priceBoxAnimation {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes priceAnimation {
  0% {
    font-size: $font-header-lg;
  }

  50% {
    font-size: 3.5rem;
  }

  100% {
    font-size: $font-header-lg;
  }
}

;@import "sass-embedded-legacy-load-done:100";
.circle-check {
  background-color: $gold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  min-width: 22px;
  position: relative;

  .uil-check:before {
    position: absolute;
    left: 53%;
    top: 51%;
    transform: translate(-50%, -50%);
    font-size: 15px;
  }

  &.is-small {
    width: 15px;
    height: 15px;
    min-width: 15px;

    .uil-check:before {
      font-size: 10px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:124";
.faq-section {
  &__accordion {
    .accordion-item {
      background-color: transparent;
      border-bottom: 1px solid rgba(205, 205, 205, 0.6) !important;
    }

    .accordion-body {
      padding-top: 0;
    }

    .accordion-button {
      background-color: transparent;
      box-shadow: none;

      &:after {
        content: none;
      }

      &:focus {
        box-shadow: none;
      }
    }

    &.is-showed {
      .accordion-button {
        color: $primary;
      }

      .accordion-item {
        background-color: white;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:130";
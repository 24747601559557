$black-olive: #3f3e3e;
$dark-blue: #0f2453;
$dark-gray-gradient: linear-gradient(#606a72, #444c52);
$darker-gray: #576067;
$gold: #b99153;
$green-pearl: #2c8f41;
$light-blue: #eff3f7;
$light-azure: #ecf5fc;
$light-gray: #f0f4f8;
$lighter-gray: #d8d8d840;
$gold-gradient: linear-gradient(#d0a35d, #b99153);
$red: #da001a;

// Breakpoints
$screen-xxl-min: 1770px;
$screen-xl-min: 1200px;
$screen-xl-max: ($screen-xxl-min - 1);
$screen-lg-min: 992px;
$screen-lg-max: ($screen-xl-min - 1);
$screen-md-min: 768px;
$screen-md-max: ($screen-lg-min - 1);
$screen-sm-min: 576px;
$screen-sm-max: ($screen-md-min - 1);
$screen-xs-min: 480px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-xxs-max: 375px;

// Fonts
$font-3xs: 0.7rem;
$font-xxs: 0.8rem;
$font-xs: 0.85rem;
$font-sm: 0.9rem;
$font-md: 0.95rem;
$font-lg: 1.1rem;
$font-xl: 1.2rem;
$font-xxl: 1.45rem;
$font-xxxl: 1.85rem;
$font-header: 2rem;
$font-header-lg: 2.5rem;

$primary: $gold;
$secondary: $dark-blue;
$success: $green-pearl;
$danger: $red;
$danger-transparent: #dc35451a;

$enable-negative-margins: true;
$body-color: $darker-gray;

$tooltip-bg: #3f3f3f;
$tooltip-max-width: 260px;

;@import "sass-embedded-legacy-load-done:4";
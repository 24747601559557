@mixin fadeInAnimation {
  animation: fadeIn 300ms linear forwards;
}

@mixin fadeOutAnimation {
  animation: fadeOut 300ms linear forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    filter: blur(2px);
  }

  100% {
    opacity: 1;
    filter: none;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    filter: none;
  }

  100% {
    opacity: 0;
    filter: blur(2px);
  }
}

@mixin pulseAnimation {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.confetti-wrapper {
  z-index: 1;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  width: 100%;
  pointer-events: none;
  position: absolute;
}

;@import "sass-embedded-legacy-load-done:6";
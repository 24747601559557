.toggle-button {
  border-radius: 6px;
  display: flex;
  align-items: center;
  height: 48px;
  color: $gray-500;
  border: 1px solid #aaa;
  transition: opacity 200ms linear;

  @include screen-sm-max {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }

  &__left-action,
  &__right-action {
    height: calc(100% - 8px);
    margin-inline: 4px;
    border-radius: 5px;
    transition:
      background-color 150ms linear,
      color 150ms linear;
    position: relative;
    width: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;

    &.is-active {
      background-color: white;
      color: $darker-gray;
    }
  }
}

;@import "sass-embedded-legacy-load-done:114";
$subscription-gold: #d4a045;
$subscription-silver: #83898e;
$subscription-brown: #936920;

.endless-holiday {
  &__accordion {
    & + & {
      margin-top: 10px;
    }

    .accordion-button {
      box-shadow: none;
      background-color: $light-blue;
      transition: background-color 300ms linear;

      &:active {
        opacity: 0.7;
      }

      &:not(.collapsed) {
        background-color: white;
        color: initial;
      }
    }

    .accordion-item {
      &.is-gold {
        border: 2px solid $subscription-gold;

        .accordion-collapse,
        .accordion-button {
          background-color: rgba(212, 160, 69, 0.05);
        }
      }

      &.is-silver {
        border: 2px solid $subscription-silver;

        .accordion-collapse,
        .accordion-button {
          background-color: rgba(131, 137, 142, 0.05);
        }
      }

      &.is-brown {
        border: 2px solid $subscription-brown;

        .accordion-collapse,
        .accordion-button {
          background-color: rgba(133, 91, 19, 0.05);
        }
      }
    }

    .no-toggle {
      .accordion-button::after {
        display: none;
      }

      .accordion-button:not(.collapsed)::after {
        display: none;
      }
    }

    .spin-input__container {
      @include screen-md-max {
        max-width: 100%;
      }
    }
  }

  &__voucher-selection {
    margin-left: 67px;
  }

  &__description-modal {
    &__close-button {
      z-index: 1;
      border-top-right-radius: 4px;

      @include screen-md-max {
        background-color: white !important;

        .uil-times {
          font-size: 20px;
          color: $darker-gray !important;
        }
      }
    }

    &__image {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      height: calc(100% + 53px);
      position: absolute;
      top: -53px;
      right: 0;

      @include screen-md-max {
        position: relative;
        top: 0;
        max-height: 420px;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  &__offer-counter {
    margin-top: 10px;
    border: 1px solid $danger;
    border-radius: 4px;
    padding: 10px;

    .icon-with-text {
      justify-content: center;

      &__text {
        color: $danger;
        text-align: center;
        display: block;
        font-size: $font-xl;
      }

      &__icon {
        font-size: $font-xl;
      }
    }

    .spinner-border-sm {
      color: $danger !important;
    }
  }

  &__summary {
    &__sticky-label {
      &__wrapper {
        position: sticky;
        bottom: 0;
        max-height: 0;
        z-index: 3;
        opacity: 0;
        transition:
          bottom 200ms linear,
          opacity 100ms linear;

        &.is-visible {
          opacity: 1;
          bottom: 180px;
        }
      }

      width: 100px;
      margin-left: auto;
      position: sticky;
      min-width: 100px;
      min-height: 150px;
      bottom: 30px;
      right: 180px;
      background-image: url('@assets/images/offers/endless-holiday/cart-label.svg');
      background-size: 100px 150px;
      background-repeat: no-repeat;

      @include screen-xl-max {
        right: 5px;
      }

      @include screen-md-max {
        display: none;
      }

      &__button {
        bottom: -32px;
        font-weight: 500;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      &__amount {
        position: absolute;
        top: -17px;
        font-weight: 500;
        font-size: $font-md;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 50%;
        transform: translateX(-50%);
        background: white;
        border-radius: 50%;
        width: 36px;
        height: 36px;

        &:after {
          content: '';
          position: absolute;
          width: 30px;
          height: 30px;
          background: black;
          z-index: -1;
          border-radius: 50%;
        }
      }
    }
  }
}

.subscription-variant {
  &__header {
    margin-bottom: 0;

    &.is-gold {
      color: $subscription-gold;
    }

    &.is-silver {
      color: $subscription-silver;
    }

    &.is-brown {
      color: $subscription-brown;
    }
  }

  &__special-dates {
    background-color: $subscription-brown;
  }

  &__expandable-row {
    & + & {
      margin-top: 10px;
    }

    .accordion-button {
      box-shadow: none;
      background-color: $light-blue;
      transition: background-color 300ms linear;

      &:not(.collapsed) {
        background-color: white;
      }
    }

    .accordion-item {
      &.is-gold {
        border-color: $subscription-gold;
      }

      &.is-silver {
        border-color: $subscription-silver;
      }

      &.is-brown {
        border-color: $subscription-brown;
      }
    }
  }

  &__price-side-card {
    position: sticky;
    top: 15px;

    &__image {
      position: relative;
      object-fit: cover;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &__content {
      border: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      color: white;
      background-color: $darker-gray;
    }

    &__summary {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #4e565e;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:107";
@import 'reset';
@import 'normalize';

@import './fonts/museo_sans';
@import 'media-queries';
@import 'variables';
@import 'general';

@import 'animations';
@import '~bootstrap/scss/bootstrap';

@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';

@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import 'react-multi-carousel/lib/styles.css';

@import 'modules/styles';
@import 'components/styles';
@import 'router';

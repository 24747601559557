.go-holiday {
  &__card {
    border-radius: 4px;
    position: relative;
    min-height: 250px;

    &__image {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 60%;
      object-fit: cover;
    }

    &__title {
      z-index: 1;
      color: white;
      font-size: 36px;
      line-height: 38px;
    }

    .subscription-with-benefit__variant-card__footer {
      color: white !important;
      font-size: 20px;
      line-height: 1.1;
      margin-top: auto;
      display: flex;
      flex-direction: column;

      &__login {
        color: white !important;
        order: 1;
        padding-bottom: 15px;

        @include screen-sm-max {
          padding-bottom: 65px;
        }
      }

      &__price {
        color: white !important;
        margin-top: 5px;
        padding-bottom: 15px;
        font-size: 30px;
      }

      .price-crossed:after {
        background-color: white;
      }
    }

    .trapezoid {
      &__wrapper {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      &__button {
        font-size: 13px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(90deg, rgba(185, 145, 83, 1) 40%, rgba(0, 0, 0, 0) 60%);

      @include screen-sm-max {
        background: linear-gradient(90deg, rgba(185, 145, 83, 1) 40%, rgba(0, 0, 0, 0) 100%);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:105";
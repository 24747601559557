.subscription-contract {
  &__wrapper {
    overflow: hidden;
    border: 1px solid $gold;
    border-radius: 4px;
    position: relative;
    background-color: black;

    @include screen-sm-max {
      flex-direction: column;
    }

    &.is-brown {
      border-color: rgb(178, 130, 54);
      background: rgb(178, 130, 54);
      background: linear-gradient(
        4deg,
        rgba(178, 130, 54, 1) 24%,
        rgba(204, 162, 79, 1) 62%,
        rgba(208, 165, 78, 1) 100%
      );
    }

    &.is-silver {
      border-color: rgb(146, 180, 196);

      background: rgb(146, 180, 196);
      background: linear-gradient(
        24deg,
        rgba(146, 180, 196, 1) 0%,
        rgba(209, 218, 227, 1) 15%,
        rgba(162, 175, 185, 1) 26%,
        rgba(162, 175, 185, 1) 45%,
        rgba(148, 159, 168, 1) 63%,
        rgba(148, 159, 168, 1) 77%,
        rgba(170, 184, 196, 1) 100%
      );
    }

    &.is-gold {
      border-color: rgb(244, 216, 33);
      background: rgb(244, 216, 33);
      background: linear-gradient(
        24deg,
        rgba(244, 216, 33, 1) 0%,
        rgba(221, 159, 17, 1) 36%,
        rgba(238, 188, 13, 1) 63%,
        rgba(226, 171, 13, 1) 66%,
        rgba(251, 188, 48, 1) 80%,
        rgba(255, 226, 1, 1) 100%
      );
    }

    &.is-platinum {
      border-color: rgb(239, 239, 240);
      background: rgb(239, 239, 240);
      background: linear-gradient(
        24deg,
        rgba(239, 239, 240, 1) 0%,
        rgba(180, 180, 183, 1) 24%,
        rgba(150, 149, 150, 1) 56%,
        rgba(144, 142, 142, 1) 100%
      );
    }

    &.is-selected {
      border-color: $success;
      box-shadow: 0 0 3px 0 $success;
    }

    &.is-recommended {
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &__benefit-icon-wrapper {
    position: relative;

    &:before {
      position: relative;
      z-index: 2;
    }

    &:after {
      background-color: #fff;
      border-radius: 50%;
      content: '';
      height: 15px;
      left: -1px;
      position: absolute;
      top: 46%;
      transform: translateY(-50%);
      width: 15px;
      z-index: 1;
    }
  }

  &__icon {
    position: absolute;
    opacity: 30%;
    left: -85px;
    top: -35px;
    height: 165px;

    @include screen-sm-max {
      height: 190px;
      right: -70px;
      top: -55px;
    }
  }

  &__date {
    border: 1px solid rgba(125, 125, 125, 0.4);
    background-color: rgba(205, 205, 205, 0.3);
    padding: 0 5px;
    color: rgba(255, 255, 255);
    display: inline-block;
    border-radius: 0 4px 4px 4px;
  }

  &__recommended-label {
    margin-top: -23px;
    position: absolute;
    width: calc(100% - 1.5rem);
    z-index: 1;
    line-height: 1;
    padding: 6px 10px;
    background-color: $primary;
    color: $white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    text-align: center;
    font-weight: 500;
    font-size: $font-sm;

    &.is-selected {
      border-top: 1px solid $success;
      border-left: 1px solid $success;
      border-right: 1px solid $success;
      box-shadow: 0 0 3px 0 $success;
    }
  }

  &__markings {
    display: inline-block;
    width: 20px;
  }
}

;@import "sass-embedded-legacy-load-done:101";
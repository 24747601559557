.offers {
  &__row {
    align-items: center;
    padding-top: 80px;

    @include screen-md-max {
      flex-direction: column-reverse !important;
      border: 1px solid rgba(87, 96, 103, 0.5);
      border-radius: 4px;
      margin-inline: 0;
      padding-top: 0;
      margin-bottom: 20px;
    }
  }

  &__header {
    font-size: $font-xxxl;

    @include screen-xs-max {
      text-align: center;
    }
  }

  &__background-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: 0 0 5px 1px rgba(66, 68, 90, 0.08);
    overflow: hidden;

    &.is-top {
      &:after {
        background-color: rgba(47, 51, 93, 0.85);
        content: '';
        height: 166px;
        position: absolute;
        right: -127px;
        top: 4px;
        width: 266px;
        transform: rotate(48deg);
      }

      &:before {
        border: 1px solid #b991537a;
        content: '';
        height: 166px;
        position: absolute;
        top: 4px;
        width: 266px;
        transform: rotate(46deg);
        right: -130px;
        z-index: 1;
      }
    }

    &.is-bottom,
    &.is-left-bottom {
      &:after {
        background-color: rgba(15, 36, 83, 0.9);
        bottom: -77px;
        content: '';
        height: 260px;
        position: absolute;
        right: -155px;
        transform: rotate(47deg);
        width: 271px;
      }

      &:before {
        border: 1px solid #b991537a;
        bottom: -82px;
        content: '';
        height: 297px;
        position: absolute;
        right: -171px;
        transform: rotate(47deg);
        width: 268px;
        z-index: 1;
      }
    }

    &.is-left-bottom {
      &:after {
        right: auto;
        left: -155px;
        width: 330px;
      }

      &:before {
        right: auto;
        left: -171px;
        height: 268px;
        width: 330px;
      }

      .offers__background-image__price {
        align-self: start !important;
        display: grid;
        left: 20px;
        right: auto;
      }
    }

    @include screen-md-max {
      height: 350px;
      min-height: 350px;
    }

    &__price {
      position: absolute;
      bottom: 40px;
      right: 20px;
      color: $white;
      font-size: $font-xl;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      line-height: 1;
      z-index: 1;

      &__price {
        font-size: $font-header;
      }

      &.is-top-right {
        top: 20px;
      }
    }

    &.is-gastro-card-boost {
      background-image: url('@assets/images/offers/gastro-card-boost/gastro-card-boost.webp');
      min-height: 380px;
    }

    &.is-gastro-card-dynamic {
      background-image: url('@assets/images/offers/gastro-card/gastro-card-dynamic.webp');
      min-height: 380px;

      @include screen-sm-max {
        background-position: right;
      }
    }

    &.is-christmas-voucher {
      background-image: url('@assets/images/offers/christmas-voucher/christmas-voucher.webp');
      min-height: 380px;

      @include screen-sm-max {
        background-position: right;
      }
    }

    &.is-gastro-card {
      @include screen-sm-max {
        object-fit: cover;
        background-image: url('@assets/images/offers/gastro-card/gastro-card-mobile.webp');
      }
    }

    &.is-subscription {
      min-height: 380px;

      @include screen-sm-max {
        background-image: url('@assets/images/offers/subscriptions/subscription.webp');
      }

      .offers__background-image__price {
        bottom: 23px;
        right: 18px;
      }
    }

    &.is-gastro-card-black-week-2024,
    &.is-gastro-card-promo-february-2025,
    &.is-gastro-card-christmas-2024 {
      min-height: 380px;

      .offers__background-image__price {
        bottom: 23px;
        right: 18px;
      }

      @include screen-sm-max {
        background-position: center;

        .offers__background-image__price {
          display: none;
        }

        &:after,
        &:before {
          content: none;
        }
      }
    }

    &.is-gastro-card-black-week-2024 {
      background-image: url('@assets/images/offers/gastro-card/gastro-card-black-week-2024.webp');

      @include screen-sm-max {
        background-image: url('@assets/images/offers/gastro-card/gastro-card-black-week-2024-mobile.webp');
      }
    }

    &.is-gastro-card-christmas-2024 {
      background-image: url('@assets/images/offers/gastro-card/gastro-card-christmas.webp');
      background-position: top;

      @include screen-sm-max {
        background-image: url('@assets/images/offers/gastro-card/gastro-card-christmas-mobile.webp');
      }
    }

    &.is-gastro-card-promo-february-2025 {
      background-image: url('@assets/images/offers/gastro-card/gastro-card-promo-february-2025.webp');
      background-position: top;

      .offers__background-image__price {
        bottom: 20px;
        right: 16px;
      }

      &:before {
        bottom: -100px;
        height: 227px;
      }

      &:after {
        bottom: -98px;
        height: 204px;
      }

      @include screen-sm-max {
        background-image: url('@assets/images/offers/gastro-card/gastro-card-promo-february-2025-mobile.webp');
      }
    }

    &.is-endless-holiday {
      align-items: end;
      min-height: 380px;
      background-image: url('@assets/images/offers/endless-holiday/endless-holiday.webp');

      @include screen-sm-max {
        background-image: url('@assets/images/offers/endless-holiday/endless-holiday-mobile.webp');
      }

      &:before {
        height: 350px;
        bottom: -100px;
      }

      &:after {
        bottom: -95px;
        height: 320px;
      }

      .offers__background-image__price {
        align-items: end;
        bottom: 23px;
        right: 18px;
      }
    }

    &.is-company-offers-contact {
      background-image: url('@assets/images/offers/company-offers-contact.webp');
      min-height: 380px;

      @include screen-sm-max {
        &:before {
          height: 160px;
          bottom: -69px;
        }

        &:after {
          bottom: -77px;
          height: 200px;
          right: -180px;
        }
      }
    }

    &.is-subscription-contract {
      background-image: url('@assets/images/offers/subscription-contract/subscription-contract.webp');
      min-height: 380px;

      @include screen-sm-max {
        background-image: url('@assets/images/offers/subscription-contract/subscription-contract-mobile.webp');
        height: 300px;
        min-height: 300px;
        background-position: 0 -10px;
      }
    }

    &.is-codes-conversion {
      background-image: url('@assets/images/offers/codes-conversion/codes-conversion.webp');
      min-height: 380px;
    }

    &.is-vip {
      background-image: url('@assets/images/offers/vip.webp');
    }

    &.is-go-holiday {
      background-image: url('@assets/images/offers/go-holiday/go-holiday.webp');
      min-height: 380px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:98";
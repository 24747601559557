.snow-flake {
  animation:
    fall linear infinite,
    sway linear infinite;
  color: #efefef;
  position: absolute;
}

@keyframes fall {
  0% {
    opacity: 20%;
  }
  50% {
    opacity: 50%;
  }
  80% {
    opacity: 1;
  }

  100% {
    top: 100vh;
    opacity: 0;
  }
}

@keyframes sway {
  0% {
    margin-left: 0;
  }
  25% {
    margin-left: 50px;
  }
  50% {
    margin-left: -50px;
  }
  75% {
    margin-left: 50px;
  }
  100% {
    margin-left: 0;
  }
}

;@import "sass-embedded-legacy-load-done:136";
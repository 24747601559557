.form-control-with-status {
  text-overflow: ellipsis;
  transition: padding-right 100ms linear;
  border: 1px solid #9099a1;
  border-radius: 4px;
  font-weight: 500;
  font-size: $font-xs;
  color: $darker-gray;
  height: 40px;

  &:focus {
    border: 1px solid #9099a1;
  }

  &.no-value {
    background: $light-blue;
  }

  &__container {
    position: relative;
    overflow: hidden;
  }

  &__spinner,
  &__succeed,
  &__failed {
    position: absolute;
    margin: auto;
    right: -25px;
    transition: right 100ms linear;
  }

  &__spinner {
    width: 1.2rem;
    height: 1.2rem;
    border: 0.2em solid;
    border-right: 0.2em solid transparent;
    top: 17px;
  }

  &__succeed,
  &__failed {
    top: 11px;
  }

  &--active,
  &--succeed,
  &--failed {
    .form-control-with-status {
      padding-right: 40px;
    }
  }

  &--active {
    .form-control-with-status__spinner {
      right: 10px;
    }
  }

  &--succeed {
    .form-control-with-status__succeed {
      right: 10px;
    }
  }

  &--failed {
    .form-control-with-status__failed {
      right: 10px;
    }

    .form-control-with-status {
      background: $danger-transparent;
      border-color: $danger;
    }
  }
}

.form-control {
  &__suffix {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.form-control-height-lg {
  height: 42px;
}

;@import "sass-embedded-legacy-load-done:116";
.order-now-button {
  min-height: 48px;

  @include screen-xs-max {
    width: 100%;
  }

  @include screen-sm-max {
    &:before {
      left: -23px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:119";
@mixin variantColor($background, $icon) {
  .codes-conversion__variant-item__coupon {
    background: $background;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .codes-conversion__variant-item__check {
    background-color: $icon;
    padding-top: 2px;
  }
}

.codes-conversion {
  &__variant-item {
    display: flex;
    border: 1px solid #adb5bd;
    background-color: #eff3f7;
    position: relative;
    border-radius: 4px;
    box-shadow: 1px 1px 37px -28px rgba(66, 68, 90, 1);

    &__active-code-label {
      position: absolute;
      left: -21px;
      top: 0;
      height: 100%;
      padding: 3px;
      background-color: #444;
      color: white;
      writing-mode: tb-rl;
      transform: rotate(-180deg);
      font-size: $font-xxs;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;

      @include screen-xs-max {
        left: 0;
      }
    }

    &.is-current {
      border-color: black;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;

      .codes-conversion__variant-item__coupon {
        border-radius: 0 !important;
      }

      @include screen-xs-max {
        max-width: calc(100% - 21px);
        margin-left: auto;

        .codes-conversion__variant-item__coupon {
          max-width: calc(33.33% - 13px);
        }
      }
    }

    &.is-disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    }

    &__coupon {
      position: relative;
      display: flex;
      align-items: center;
      color: white;
      line-height: 15px;
      padding-block: 10px;
      padding-inline: 10px;
    }

    &.is-gold {
      $background: linear-gradient(184deg, #f6af33 0, #dfb20d 5%, #dd9f10 62%, #cf830d 100%);
      $icon: #db9a10;

      @include variantColor($background, $icon);
    }

    &.is-silver {
      $background: linear-gradient(
        186deg,
        #68829c 0,
        #718aa3 2.7%,
        #b5c4d3 23.86%,
        #7e91a2 36%,
        #8494a1 66.9%,
        #7f8b94 76%,
        #90a1b0 100%
      );
      $icon: #7d7d7d;

      @include variantColor($background, $icon);
    }

    &.is-brown {
      $background: linear-gradient(184deg, #b37e17 0, #91600f 42%, #a76c0b 100%);
      $icon: #97630e;

      @include variantColor($background, $icon);
    }

    &.is-gray {
      $background: #9099a1;
      $icon: #9099a1;

      @include variantColor($background, $icon);
    }

    &.is-default {
      $background: #e5eaef;
      $icon: $darker-gray;

      @include variantColor($background, $icon);
    }

    &__check {
      min-width: 17px;
      min-height: 17px;
      width: 17px;
      height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border-radius: 50%;
      margin-right: 5px;

      &.is-default {
        background-color: $darker-gray;
      }
    }

    &--current {
      border-color: #3f3f3f;
    }
  }

  &__arrow {
    position: absolute;
    right: 0;

    &__3 {
      bottom: 39px;
      opacity: 40%;
    }

    &__2 {
      bottom: 26px;
      opacity: 30%;
    }

    &__1 {
      bottom: 13px;
      opacity: 20%;
    }

    &__0 {
      opacity: 10%;
      bottom: 0;
    }
  }

  &__personal-data__information-box {
    @include screen-xs-max {
      grid-template-columns: 1fr;
      text-align: start;

      &:after {
        content: none;
      }
    }

    .subscription-with-benefit__personal-data__information-box__point {
      margin-right: 0;

      @include screen-xs-max {
        margin-right: 10px;
      }
    }
  }

  &__expandable-product-description {
    .expandable-product-description {
      max-height: 120px;
    }
  }

  &__no-options-available-block {
    position: relative;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    padding: 0 18px 0 18px;
    margin-top: 0;
    transition: all 200ms linear;

    &.is-visible {
      margin-top: 13px;
      padding: 18px;
      max-height: 500px;
      opacity: 1;
    }
  }
}

;@import "sass-embedded-legacy-load-done:104";
.password-form-group {
  &__visibility-icon {
    cursor: pointer;
    font-size: $font-lg;
    color: $text-muted;
    position: absolute;
    right: 10px;
    top: 35px;
    transition: right 100ms linear;

    @include screen-xl-max {
      top: 29px;
    }

    @include screen-xl-min {
      top: 28px;
      max-width: 980px;
    }

    &.is-label-top {
      @include screen-xl-min {
        top: 27px;
      }
    }

    &.is-password-check-eye-moved {
      right: 30px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:117";
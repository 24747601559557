@mixin screen-xxs-max {
  @media (max-width: #{$screen-xxs-max}) {
    @content;
  }
}

@mixin screen-xs-min {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

@mixin screen-xs-max {
  @media (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin screen-sm-min {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin screen-sm-max {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin screen-md-min {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin screen-md-max {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin screen-lg-min {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin screen-lg-max {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin screen-xl-min {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin screen-xl-max {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin screen-xxl-min {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

;@import "sass-embedded-legacy-load-done:3";
.app-footer {
  &__background {
    background-image: url('@assets/images/footer_logo_shape.webp');
    background-repeat: no-repeat;
    background-position: center 0;

    @include screen-lg-max {
      background-image: none;
    }
  }

  &__terms-wrapper {
    border-top: 1px solid #f4f4f4;
    background-color: $light-azure;

    &__content {
      max-width: 600px !important;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }
  }

  &__download-banner {
    background: rgb(19, 65, 141);
    background: linear-gradient(180deg, rgba(19, 65, 141, 1) 0%, rgba(8, 29, 76, 1) 100%);
    width: 85%;
    padding-left: 35px;
    padding-block: 20px;
    position: relative;
    border-radius: 4px;
    margin: 0 auto 50px;

    @include screen-lg-max {
      padding-left: 0;
      padding-bottom: 0;
      width: 100%;
      margin-bottom: 10px;
    }

    &__image {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      max-width: 65%;

      @include screen-lg-max {
        margin-top: 20px;
        position: relative;
        max-width: 100%;
        width: 100%;
        max-height: 150px;
        object-fit: cover;
      }
    }
  }

  &__statistics-counter {
    &__count {
      font-size: 55px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:110";
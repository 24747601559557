.offer-modal {
  @include screen-lg-max {
    &.modal-dialog {
      align-items: end;
      margin: 0;
    }
  }

  &__header {
    margin-left: 40%;

    @include screen-lg-max {
      margin-left: 0;
    }

    .modal-title {
      width: 90% !important;

      @include screen-lg-max {
        width: 85%;
      }
    }

    .btn-close {
      position: relative;
      padding-right: 15px;

      &:after {
        content: '';
        background-color: #dee2e6;
        height: calc(100% + 30px);
        position: absolute;
        width: 1px;
        left: -10px;
        top: -15px;
      }
    }
  }

  &__body {
    margin-left: 40%;
    display: flex;
    flex-direction: column;
    padding: 0;

    @include screen-lg-max {
      margin-left: 0;
    }
  }

  &__footer {
    margin-left: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0;
    padding-block: 0;
    border-top: 0;

    @include screen-lg-max {
      margin-left: 0;
    }
  }

  &__price {
    color: $primary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f2f3f3;
    border-top: 1px solid #e6e7e8;
    border-bottom: 1px solid #e6e7e8;
  }

  &__image {
    position: absolute;
    left: -67%;
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
    width: 67%;
    object-fit: cover;
    box-shadow: 9px 0 12px -3px rgb(66 68 90 / 14%);
    height: calc(100% + 96px);
    top: -56px;

    @include screen-lg-max {
      position: relative;
      width: 100%;
      height: 150px;
      left: 0;
      top: 0;
      border-radius: 0;
    }
  }
}

.circle-step {
  position: relative;

  &__current {
    position: absolute;
    right: 6px;
    top: 8px;

    @include screen-lg-max {
      top: 7px;
    }
  }
}

@include screen-lg-max {
  .modal.fade {
    background-color: #000000ad;
  }
}

;@import "sass-embedded-legacy-load-done:99";
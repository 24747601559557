.resorts-gallery {
  &__resorts {
    padding: 30px 30px 0 30px;

    &__item {
      padding: 0 5px;
    }

    .react-multi-carousel-list {
      padding-bottom: 50px;
    }

    &__wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 22px 11px;
    }

    .react-multi-carousel-dot {
      @include screen-xxl-min {
        display: none;
      }

      & > button {
        border-radius: 50%;
        border: none;
        background-color: $darker-gray;
        opacity: 50%;
        width: 8px;
        height: 8px;
      }

      &--active > button {
        background-color: $darker-gray;
        opacity: 100%;
      }
    }

    .react-multi-carousel-dot-list {
      bottom: 10px;
    }

    .react-multiple-carousel__arrow {
      background: transparent;

      &--right {
        right: -5px;
      }

      &--left {
        left: -5px;
      }
    }

    .image-gallery-index {
      padding: 7px 15px;
      border-radius: 2px;
    }
  }

  &__resort {
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include screen-lg-max {
      border: 1px solid #e5e5e5;
    }

    &__description {
      display: flex;
      flex-direction: column;

      @include screen-xs-min {
        min-height: 115px;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:131";
.upcoming-product {
  margin-bottom: 70px;
  margin-top: 70px;
  position: relative;

  @include screen-sm-max {
    padding-bottom: 20px;
    margin-top: 0px;
    margin-bottom: 30px;
    max-height: 250px;
    overflow: hidden;
  }

  &__wrapper {
    filter: blur(20px);
    opacity: 0.3;
    pointer-events: none;
    user-select: none;

    .offers__row {
      padding-top: 0;
      height: 320px;

      @include screen-lg-max {
        height: auto;
      }
    }
  }

  &__message {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    font-weight: bold;
    text-align: center;

    @include screen-sm-max {
      width: 90%;
    }
  }
}

;@import "sass-embedded-legacy-load-done:128";
.offer-carousel {
  position: relative;

  @include screen-lg-max {
    padding-top: 20px;
    margin-bottom: 20px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 75%;
    background-color: $light-azure;
    top: 0;
    left: 0;
  }

  .carousel-item {
    border-radius: 4px;
  }

  .carousel-indicators {
    bottom: -50px;

    button {
      width: 8px;
      height: 8px;
      opacity: 30%;
      background-color: black;
      border-radius: 50%;
    }

    button.active {
      width: 12px;
      height: 12px;
      background-color: $dark-blue;
      opacity: 1;
      margin-top: -2px;
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 100px;

    @include screen-lg-max {
      display: none;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .carousel-control-next {
    right: -25px;
  }

  .carousel-control-prev {
    left: -25px;
  }
}

.basic-offer-slide {
  min-height: 450px;
  border-radius: 3px;
  background-image: url('@assets/images/hp.jpg');
  padding-left: 90px;
  padding-block: 60px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center -100px;

  &:after {
    background-color: rgba(47, 51, 93, 0.85);
    bottom: -54px;
    content: '';
    position: absolute;
    transform: rotate(47deg);
    z-index: 1;
    pointer-events: none;

    left: -315px;
    height: 50vw;
    width: 60vw;
  }

  &:before {
    bottom: -54px;
    content: '';
    position: absolute;
    z-index: 2;
    border: 1px solid rgba(185, 145, 83, 0.478);
    transform: rotate(48deg);
    pointer-events: none;

    left: -315px;
    height: 50vw;
    width: 60vw;
  }

  @include screen-lg-max {
    border: 1px solid rgba(87, 96, 103, 0.5);
    padding: 0;
    min-height: auto;
    background-color: $white;
    background-image: none;

    &:after,
    &:before {
      content: none;
    }
  }
}

.offer-slide {
  &__content {
    display: flex;
    flex-direction: column;
    z-index: 5;
    position: relative;

    &__items {
      @include screen-lg-max {
        background: $white;
        z-index: 5;
        position: relative;
      }
    }
  }

  &__text {
    color: $white;

    @include screen-lg-max {
      color: $darker-gray;
    }
  }

  &__image {
    display: none;

    @include screen-lg-max {
      display: block;
      height: 150px;
      width: 100%;
      object-fit: cover;
    }
  }

  &__toggle {
    padding-right: 60px;

    @include screen-lg-max {
      padding-right: 0;
      margin-block: 20px;
      margin-left: 20px;
    }
  }

  &__price {
    &__container {
      position: absolute;
      right: 40px;
      top: 30px;
      display: flex;
      flex-direction: column;

      * {
        z-index: 2;
      }

      @include screen-sm-max {
        display: none;
      }

      &:after {
        background-color: rgba(15, 36, 83, 0.9);
        bottom: -106px;
        content: '';
        position: absolute;
        right: -197px;
        transform: rotate(47deg);
        height: 352px;
        width: 312px;
        z-index: 1;
      }

      &:before {
        border: 1px solid rgba(185, 145, 83, 0.478);
        bottom: -106px;
        content: '';
        height: 316px;
        position: absolute;
        right: -197px;
        transform: rotate(49deg);
        width: 317px;
        z-index: 2;
      }
    }

    &__header {
      font-size: 45px;
      line-height: 45px;
      color: $white;

      @include screen-lg-max {
        font-size: $font-header;
        color: $primary;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:97";
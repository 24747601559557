.date-picker {
  background-color: #fff;
  position: relative;
  width: auto;
  padding-right: 10px;

  &.is-with-month-name {
    min-width: unset;
    display: inline;
  }

  &.without-days {
    // overwrite
    .rdrMonths {
      display: none;
    }

    .rdrMonthAndYearWrapper {
      padding: 0;
    }

    .rorCalendarWrapper {
      display: flex;
    }
  }

  &.without-min-width {
    min-width: initial;
  }

  &__placeholder {
    font-size: $font-md;
    color: #dadada;
  }

  &__clear {
    cursor: pointer;
    position: absolute;
    height: 16px;
    width: 16px;
    top: 0;
    right: 7px;
    bottom: 0;
    margin: auto;

    &:after {
      background-color: $dark-blue;
      border-radius: 50%;
      color: #fff;
      content: '\00d7';
      cursor: pointer;
      font-size: 12px;
      height: 16px;
      width: 16px;
      line-height: 1;
      padding: 2px;
      position: absolute;
      text-align: center;
    }
  }

  &__date {
    border: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.45rem 0;
    font-size: $font-md;
    font-weight: 500;
    line-height: 1.5;
    color: #6c757d;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    height: 33px !important;

    &.is-in-promocode-prefixes {
      background-color: #f3f3f3;
    }
  }

  &__wrapper {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 100%;

    @include screen-xs-max {
      position: fixed;
      left: 23px;
      top: 50%;
      transform: translateY(-50%);
      background: white;
    }

    .rdrMonthAndYearWrapper {
      margin-top: -13px;
      background: white;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
  }

  &.is-disabled {
    background-color: black;
  }
}

;@import "sass-embedded-legacy-load-done:134";
.image-gallery {
  &,
  .image-gallery-content,
  .image-gallery-swipe,
  .image-gallery-slide,
  .image-gallery-slide-wrapper {
    height: 100%;
  }

  .image-gallery-right-nav,
  .image-gallery-left-nav {
    padding: 20px 10px;
  }

  .image-gallery-svg {
    max-height: 60px;
    max-width: 40px;

    @include screen-sm-min {
      max-height: 40px;
      max-width: 20px;
    }
  }

  .image-gallery-thumbnail-image {
    height: 41px;
    width: 100%;
    object-fit: cover;
  }

  .image-gallery-thumbnail {
    border: 2px solid white;
    height: 45px;
    width: 14%;
  }

  .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 8px;
  }

  .image-gallery-thumbnails {
    position: relative;
    overflow: initial;
    z-index: 2;
  }

  .image-gallery-thumbnails-container {
    margin-top: -27.5px;
  }

  .image-gallery-slides {
    height: 100%;
    z-index: 2;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    object-fit: cover;
    height: 100%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  .image-gallery-index {
    top: auto;
    bottom: 16px;
    left: 16px;
    width: fit-content;
    padding: 5px 10px;
    font-size: $font-sm;
    font-weight: 500;
  }
}

;@import "sass-embedded-legacy-load-done:129";